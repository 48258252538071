body {
  margin: 0;
  font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "IBM Plex Sans", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  left: -6px;
  top: -6px;
}
.MuiSlider-markLabel {
  top: 14px !important;
  font-size: 14px !important;
}
.MuiSlider-thumb {
  width: 8px !important;
  height: 8px !important;
  top: 8px !important;
  margin-left: -4px !important;
}
.MuiSlider-rail,
.MuiSlider-track {
  height: 4px !important;
}
.MuiSlider-track {
  background-color: #5b5763 !important;
}
.MuiSlider-thumbColorSecondary {
  background-color: white !important;
}
.MuiSlider-thumbColorSecondary.MuiSlider-active {
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.2) !important;
}
.MuiSlider-mark {
  width: 2px !important;
  height: 6px !important;
}
.MuiSlider-markLabel {
  color: #5b5763 !important;
}
.MuiSlider-markLabelActive {
  color: #8e8e8e !important;
}
